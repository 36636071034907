import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/htr'
  },
  {
    path: '/htr',
    component: () => import('@/views/HtrHome.vue'),
    meta: {
      title: 'HTR - Looloo Technology',
    },
  },
  { 
    path: "/lpr",
    component: () => import('@/views/LprHome.vue'),
    meta: {
      title: 'LPR - Looloo Technology',
    },
  },
  { 
    path: "/nlp",
    component: () => import('@/views/NlpHome.vue'),
    meta: {
      title: 'NLP - Looloo Technology',
    },
  },
  { 
    path: "/esu",
    component: () => import('@/views/EsuHome.vue'),
    meta: {
      title: 'ESU - Looloo Technology',
    },
  },
  { 
    path: "/spr",
    component: () => import('@/views/SprHome.vue'),
    meta: {
      title: 'SPR - Looloo Technology',
    },
  },
  {
    path: '/ocr',
    component: () => import('@/templates/DemoTemplate.vue'),
    children: [
      {
        path: '/',
        redirect: '/ocr/businesscard'
      },
      {
        path: 'businesscard',
        component: () => import('@/views/BuscardHome.vue'),
        meta: {
          title: 'OCR - Looloo Technology',
        },
      },
      {
        path: 'transformer-base',
        component: () => import('@/views/TransformerBaseHome.vue'),
        meta: {
          title: 'OCR - Looloo Technology',
        },
      },
    ],
  },
  // { 
  //   path: "/esu",
  //   component: () => import('@/views/EsuHome.vue'),
  // },
  {
    path: "/demo",
    component: () => import('@/templates/DemoTemplate.vue'),
    children: [
      {
        path: '/',
        redirect: '/demo/htr'
      },
      { 
        path: "lpr",
        component: () => import('@/views/demo/LprHome.vue'),
        meta: {
          title: 'LPR - Looloo Technology',
        },
      },
      { 
        path: "nlp",
        component: () => import('@/views/demo/NlpHome.vue'),
        meta: {
          title: 'NLP - Looloo Technology',
        },
      },
      { 
        path: "htr",
        component: () => import('@/views/demo/HtrHome.vue'),
        meta: {
          title: 'HTR - Looloo Technology',
        },
      },
      {
        path: 'ocr',
        component: () => import('@/templates/DemoTemplate.vue'),
        children: [
          {
            path: '/',
            redirect: '/demo/ocr/businesscard'
          },
          {
            path: 'businesscard',
            component: () => import('@/views/demo/BuscardHome.vue'),
            meta: {
              title: 'OCR - Looloo Technology',
            },
          },
          {
            path: 'carbook',
            component: () => import('@/views/demo/CarbookHome.vue'),
            meta: {
              title: 'OCR - Looloo Technology',
            },
          },
          {
            path: 'receipt',
            component: () => import('@/views/demo/InvoiceHome.vue'),
            meta: {
              title: 'OCR - Looloo Technology',
            },
          },

          {
            path: 'letterofcredit',
            component: () => import('@/views/demo/LocHome.vue'),
            meta: {
              title: 'OCR - Looloo Technology',
            },
          },
        ],
      }
    ]

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Looloo Technology'
router.beforeEach((to, from, next) => {
  console.log(to, from)
  document.title = to.meta.title || DEFAULT_TITLE;
  next();
});

export default router
